import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { theme } from '../Theme';
import { toRem } from '../../helpers';
import { ReactComponent as Arrow } from '../../assets/svg/Arrow.svg';

const CTALinkStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colorScheme.primary};
  border: 2px solid ${theme.colorScheme.primary};
  color: ${theme.colorScheme.primarytext};
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.medium};
  border-radius: ${toRem(100)};
  padding: 0.5rem 1rem;
  max-width: fit-content !important;
  text-decoration: none;

  svg {
    position: relative;
    fill: ${theme.colorScheme.primarytext};
    height: 1em;
    width: 1em;
    margin-left: ${toRem(10)};
    path {
      fill: ${theme.colorScheme.primarytext};
    }
  }

  :hover {
    background-color: ${theme.colorScheme.black};
    border: 2px solid ${theme.colorScheme.black};
    color: ${theme.colorScheme.white};
    svg {
      left: 5px;
      animation: arrowHover 0.5s;
      fill: ${theme.colorScheme.white};
      path {
        fill: ${theme.colorScheme.white};
      }
    }
  }
  &.secondary {
    background-color: ${theme.colorScheme.transparent};
    border: 2px solid ${theme.colorScheme.black};
    color: ${theme.colorScheme.black};

    :hover {
      background-color: ${theme.colorScheme.black};
      border: 2px solid ${theme.colorScheme.black};
      color: ${theme.colorScheme.white};
    }
  }
  &.plain {
    background: none;
    color: inherit;
    border: none;
    text-decoration: underline;
    svg {
      fill: inherit;
      path {
        fill: inherit;
      }
    }
    :hover {
      background-color: none;
      color: inherit;
      border: none;
      text-decoration: underline;
      svg {
        fill: inherit;
        path {
          fill: inherit;
        }
      }
    }
  }

  @keyframes arrowHover {
    from {
      left: 0px;
    }
    to {
      left: 5px;
    }
  }
`;

const CTALink = ({
  children,
  hideArrow,
  secondary,
  internalLink,
  externalLink,
  plain
}) => {
  if (externalLink) {
    return (
      <a
        href={externalLink}
        className={cx(
          CTALinkStyles,
          secondary && 'secondary',
          plain && 'plain'
        )}
      >
        {children}
        {!hideArrow && <Arrow />}
      </a>
    );
  }

  if (internalLink) {
    return (
      <Link
        to={internalLink}
        className={cx(
          CTALinkStyles,
          secondary && 'secondary',
          plain && 'plain'
        )}
      >
        {children}
        {!hideArrow && <Arrow />}
      </Link>
    );
  }
  return null;
};

export default CTALink;
