import React from 'react';
import { css, cx } from 'linaria';

import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';

import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import { useNotification } from '@jetshop/core/components/Notifications';

import { ReactComponent as CartIcon } from '../../assets/svg/Cart.svg';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import ProductGrid from '../ProductGrid';

import addMultipleToCartMutation from '../../gql/mutations/addMultipleToCart.gql';
import cartQuery from '../../gql/queries/cartQuery.gql';

export function Favourites() {
  const listId = null;

  const { products, loading } = useProductListItems(listId);
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;
  console.log(validItems);
  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <CartIcon />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('There are no products in your saved items list.')}</p>
      </MaxWidth>
    );
  }

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('Loading your saved items…')}</p>
      </MaxWidth>
    );
  }

  return (
    <MaxWidth className={cx(container, loading ? 'loading' : null)}>
      <h1>{t('Saved items')}</h1>
      <ProductGrid listId={listId} products={products} />

      <div className="add-clear">
        <Button
          hideArrow
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {validItemCount > 0
            ? mutationLoading
              ? t('One moment…')
              : t(
                  '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                  { productCount: validItemCount }
                )
            : t('No valid items to add to cart')}
        </Button>
        <ClearList listId={listId}>{t('Clear list')}</ClearList>
      </div>
    </MaxWidth>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children, listId }) {
  const { clear } = useProductList(listId);
  return (
    <Button hideArrow secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const container = css`
  margin-top: 2em;
  margin-bottom: 2em;
  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 600;
  }
  .add-clear {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;
    .secondary {
      border-radius: 8px;
      border: 1px solid #000000;
    }
    .add-all {
      position: relative;
      overflow: hidden;
      background: linear-gradient(
        ${theme.colorScheme.addtocartswipeangle},
        ${theme.colorScheme.addtocartlight},
        ${theme.colorScheme.addtocartdark}
      );
      color: ${theme.colorScheme.addtocarttext};
      border: none;
      border-radius: 8px;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      svg path {
        fill: ${theme.colorScheme.addtocarttext};
      }
    }
    .add-all:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      border: none;
      color: ${theme.colorScheme.addtocarttext};
      svg path {
        fill: ${theme.colorScheme.addtocarttext};
      }
    }

    /* Idle streak: Moves continuously left to right */
    .add-all::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(${theme.colorScheme.addtocartswipe}, 0) 10%,
        rgba(${theme.colorScheme.addtocartswipe}, 0.5) 50%,
        rgba(${theme.colorScheme.addtocartswipe}, 0) 90%
      );
      filter: blur(8px);
      transform: skewX(-20deg);
      animation: streak 13s linear infinite;
    }

    /* Hover streak: Bursts from center and expands outwards */
    .add-all::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0%;
      height: 30%;
      background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 50%
      );
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    .add-to-cart:hover::after {
      animation: ripple 1s forwards;
    }

    /* Keyframes for the hover ripple effect */
    @keyframes ripple {
      0% {
        width: 0%;
        height: 30%;
        opacity: 1;
      }
      100% {
        width: 300%;
        height: 300%;
        opacity: 0;
      }
    }

    /* Keyframes for the idle streak */
    @keyframes streak {
      0% {
        left: -100%;
      }
      23.08% {
        /* Streak moves for 3s out of 13s total */
        left: 150%;
      }
      100% {
        left: 150%;
      }
    }
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colorScheme.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;
