import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from '@apollo/react-hooks';
import footerQuery from '../../../gql/queries/FooterQuery.gql';
import FooterContent from './FooterContent/FooterContent';
import TrustpilotBlock from './TrustpilotBlock/TrustpilotBlock';

const getFooterCategoryId = () => {
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;
  switch (envChannelId) {
    case '1':
      return 153;
    case '5':
      return 378;
    case '9':
      return 376;
    case '13':
      return 380;
    case '17':
      return 382;
    case '18':
      return 384;
    default:
      return 153;
  }
};

const Footer = () => {
  const footerCategoryId = getFooterCategoryId();
  const { data } = useQuery(footerQuery, {
    variables: { footerCategoryId: footerCategoryId }
  });

  const components = {
    BADGE: 'badge',
    FLAGS: 'flags',
    LINKGROUPONEITEM: 'linkGroupOneItem',
    LINKGROUPTWOITEM: 'linkGroupTwoItem',
    PAYMENTICON: 'paymentIcon',
    TRUSTPILOTBLOCK: TrustpilotBlock,
    SITEFOOTER: FooterContent
  };

  return (
    <>
      <ContentRenderer
        items={data?.category?.data?.items ?? []}
        components={components}
      />
    </>
  );
};

export default Footer;
