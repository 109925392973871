import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { toRem } from '../../helpers';
import MaxWidth from '../Layout/MaxWidth';
import { ReactComponent as Check } from '../../assets/svg/UspCheck.svg';

const InfoBarWrapper = styled('div')`
  background-color: ${theme.colorScheme.softblack};
  color: ${theme.colorScheme.white};
  ${theme.above.lg} {
    background-color: ${theme.colorScheme.superlightgrey};
    color: ${theme.colorScheme.black};
  }
  ${theme.above.md} {
    order: 2;
  }
  ${theme.below.md} {
    order: 1;
  }
`;

const InfoBarContent = styled(MaxWidth)`
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0 ${toRem(20)};

  ${theme.above.md} {
    padding: 0 ${toRem(36)};
  }

  ${theme.above.lg} {
    justify-content: flex-end;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const InfoBarItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: ${toRem(8)} 0;
  margin-right: ${toRem(24)};
  &:last-child {
    margin-right: 0;
  }
`;

const Text = styled('p')`
  font-family: 'Poppins';
  font-size: ${toRem(12)};
  font-weight: ${theme.fontWeights.regular};
  line-height: ${toRem(18)};
  margin-left: ${toRem(6)};
`;

const UspCheck = styled(Check)`
  path {
    fill: ${theme.colorScheme.primary};
  }
`;

const style = {
  InfoBarWrapper,
  InfoBarContent,
  InfoBarItemWrapper,
  Text,
  UspCheck
};

export default style;
