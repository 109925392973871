import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/Search.svg';
import { ReactComponent as CloseSmall } from '../../../../assets/svg/CloseSmall.svg';

const PosedSearchBarContainer = styled('div')`
  ${theme.below.md} {
    margin-bottom: 0.5rem;
  }
  position: relative;
  width: 100%;

  ${theme.above.md} {
    width: 8rem;
    max-width: 100%;
    margin-bottom: 0;
    margin-right: 30px;
  }

  svg {
    position: absolute;
    right: ${toRem(8)};
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: ${toRem(10)};
    path {
      fill: ${theme.colorScheme.black};
    }
    rect {
      fill: ${theme.colorScheme.black};
    }
  }
`;

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 4px;
`;

const searchFieldStyles = css`
  &[data-flight-searchfield] > * {
    height: 2rem;
    width: 100% !important;
    ${theme.below.md} {
      border-style: solid !important;
      border-width: 2px !important;
      border-color: ${theme.colorScheme.primary} !important;
      border-radius: 4px;
    }

    input {
      height: 100%;
      width: 100%;
      border: 0;
      border-radius: ${toRem(4)};
      background: ${theme.colorScheme.superlightgrey};
      font-size: ${toRem(16)};
      padding: 0 ${toRem(30)} 0 1rem;

      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button,
      ::-webkit-search-results-button,
      ::-webkit-search-results-decoration {
        display: none;
      }

      -webkit-appearance: none;

      :focus {
        outline: 1px solid ${theme.colorScheme.primary};
        outline-offset: -1px;
      }

      ${theme.above.md} {
        margin-right: ${toRem(5)};
        border-radius: 0;
        background: ${theme.colorScheme.softblack};
        border-bottom: 1px solid ${theme.colorScheme.primary};
        color: ${theme.colorScheme.white};
        padding: 0 ${toRem(5)};

        :focus {
          outline: none;
        }
        ::placeholder {
          color: ${theme.colorScheme.white};
          opacity: 1;
        }
      }
    }
  }

  [data-flight-searchfield-cancel] {
    display: none;
    height: 100%;
    min-width: 48px;
    width: fit-content;
  }

  [data-flight-searchfield-flyout] {
    border: 0;

    ${theme.below.md} {
      top: ${toRem(40)};
      width: 100%;
      border-radius: ${toRem(4)};
    }
    ${theme.above.md} {
      width: 100%;
      top: ${toRem(44)};
    }

    h2 {
      padding: ${toRem(24)} ${toRem(16)} ${toRem(12)} ${toRem(16)};
      text-transform: uppercase;
      font-size: ${toRem(12)};
      font-weight: ${theme.fontWeights.bold};
      color: ${theme.colorScheme.black};
      position: relative;
      border-top: 1px solid #ededed;

      &:first-child {
        border-top: 0;
        padding: ${toRem(12)} ${toRem(16)};
      }

      ::after {
        content: '';
        width: ${toRem(24)};
        height: ${toRem(2)};
        background-color: ${theme.colorScheme.primary};
        position: absolute;
        bottom: ${toRem(8)};
        left: ${toRem(16)};
      }
    }

    li {
      border-top: 1px solid #ededed;
      padding: ${toRem(16)};
      &:first-child {
        border-top: 0;
      }
      :hover {
        background-color: ${theme.colorScheme.superlightgrey};
      }
    }

    a {
      display: block;
      font-size: ${toRem(12)};
      line-height: ${toRem(14)};
      color: ${theme.colorScheme.black};
      text-decoration: none;
    }

    ul + h2 {
      margin-top: 0;
    }
  }
`;

// Desktop search components
const Wrapper = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  ${theme.below.md} {
    display: none;
  }
`;

const Button = styled('button')`
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: ${toRem(24)};

  :hover {
    svg {
      path {
        fill: ${theme.colorScheme.primary};
      }
    }
  }
`;

const Search = styled(SearchIcon)`
  height: ${toRem(18)};
  width: ${toRem(18)};
`;

const CloseSearch = styled(CloseSmall)`
  height: ${toRem(16)};
  width: ${toRem(16)};
  path {
    fill: ${theme.colorScheme.primary};
  }
  rect {
    fill: ${theme.colorScheme.primary};
  }
`;

const style = {
  PosedSearchBarContainer,
  InnerSearchBar,
  searchFieldStyles,
  Wrapper,
  Button,
  Search,
  CloseSearch
};

export default style;
