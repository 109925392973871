import PropTypes from 'prop-types';
import React from 'react';
import StockOrb from './StockOrb';
import { styled } from 'linaria/react';

const StockStatusWrapper = styled('p')`
  margin: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 130%;
  margin-top: 0.25rem;
`;

const StockStatusIndicator = ({ status, text, ...props }) => {
  return (
    <StockStatusWrapper {...props}>
      <StockOrb status={status} />
      <span>{text}</span>
    </StockStatusWrapper>
  );
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf([
    'inStock',
    'soonInStock',
    'outOfStock',
    'notifyWhenBack'
  ]),
  text: PropTypes.string
};

export default StockStatusIndicator;
