import React, { useState } from 'react';
import { cx, css } from 'linaria';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Button from './Button';
import { useIntl } from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';

export const List = ({
  type = 'plain',
  columns,
  items,
  children,
  limit,
  className,
  mainCategory,
  closeNav
}) => {
  const t = useIntl();
  const [limited, setLimited] = useState(
    limit && [...(items || []), ...(children || [])].length > limit + 1
  );
  const ListTag = type === 'ordered' ? 'ol' : 'ul';
  const limitedItems =
    limited && items?.length > limit + 1 ? items.slice(0, limited) : items;
  return (
    <ListTag
      className={cx(
        listClassName,
        type,
        columns && `columns-${columns}`,
        className
      )}
    >
      {limitedItems?.map(item => (
        <ListItem {...item} />
      ))}
      {limited && Array.isArray(children)
        ? [...children].slice(0, limit)
        : children}
      {limited && mainCategory && (
        <ListItem className="show-more-item">
          <CategoryLink category={mainCategory} onClick={closeNav}>
            <Button hideArrow plain size="small">
              {t('Show more')}
            </Button>
          </CategoryLink>
        </ListItem>
      )}
    </ListTag>
  );
};

export const ListItem = ({ text, href, children, ...props }) => {
  return (
    <StyledListItem {...props}>
      {href ? <Link to={href}>{text || children}</Link> : text || children}
    </StyledListItem>
  );
};

const StyledListItem = styled.li``;
const listClassName = css`
  .show-more-item {
    padding-top: 0.25rem;
  }
  &.plain {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 170px;
    overflow: auto;
  }
  &.columns-2,
  &.columns-3,
  &.columns-4 {
    column-gap: 1rem;
    columns: 2;
  }
  &.columns-3 {
    columns: 3;
  }
  &.columns-3 {
    columns: 4;
  }
`;

export default List;
