import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../components/Theme';
import { ProductGridCheckout } from '../../components/ProductGridCheckout';
import ProductCarouselCheckout from '../../components/ProductCarouselCheckout';
import Heading from '../../components/ui/Heading';
import MaxWidth from '../../components/Layout/MaxWidth';
import FullWidth from '../../components/Layout/FullWidth';
import { Above } from '@jetshop/ui/Breakpoints';
import { useAddwish } from '@jetshop/flight-addwish';
import helloRetailQuery from '../../components/HelloRetail/HelloRetailQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import helloRetailIds from '../../components/HelloRetail/RecommendationIds';
import { useQuery } from '@apollo/react-hooks';

const Container = styled('div')`
  padding-top: 18px;
  padding-bottom: 4px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    background: ${theme.colorScheme.superlightgrey};
  }
  ${theme.below.sm} {
    padding-top: 0px;
    padding-bottom: 4px;
  }
  .CarouselInnerWrapper {
    width: 150px !important;
  }
  .heading-title {
    margin-bottom: 0px;
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
export const productGrid = css`
  flex-wrap: nowrap !important;
  ${theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    ${theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const getHelloRetailRecId = (recomName, selectedChannel) => {
  return helloRetailIds[selectedChannel?.name][recomName];
};

const HelloRetailProductRecomCheckout = ({
  loading = false,
  recomName,
  title,
  productNumbers,
  notificationId,
  dismiss,
  toggleSelection,
  selectedProducts,
  ...rest
}) => {
  const { selectedChannel } = React.useContext(ChannelContext);

  const relatedId = getHelloRetailRecId(recomName.value, selectedChannel);

  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [relatedId]: {
        productNumbers
      }
    },
    pageLoaded: !loading
  });
  const relatedArticleNumbers = (
    addWishResponseBoxItems[relatedId]?.items || []
  ).map(item => item.productNumber);

  const relatedProductResult = useQuery(helloRetailQuery, {
    variables: {
      articleNumbers: relatedArticleNumbers
    },
    skip: relatedArticleNumbers.length === 0
  });

  const relatedProducts =
    relatedProductResult?.data?.products.map(product => {
      return {
        ...product,
        addwishData: addWishResponseBoxItems[relatedId]?.items.find(
          item => item.productNumber === product.articleNumber
        )
      };
    }) || [];

  return !relatedProducts.length ? null : (
    <Container>
      {title?.value ? (
        <MaxWidth>
          <Heading level="2" asLevel={4} className="heading-title">
            {title.value}
          </Heading>
        </MaxWidth>
      ) : null}
      <Above breakpoint="xl">
        {matches =>
          matches ? (
            <MaxWidth className="above-xl">
              <ProductGridCheckout
                products={relatedProducts}
                className={productGrid}
                widths={{
                  xl: title?.value ? 1 / 4 : 1 / 3 //title added temporarily to width and readMore buttons.
                }}
                dismiss={dismiss}
                notificationId={notificationId}
                toggleSelection={toggleSelection}
                selectedProducts={selectedProducts}
              />
            </MaxWidth>
          ) : (
            <FullWidth className="below-xl">
              <ProductCarouselCheckout
                products={relatedProducts}
                className={productGrid}
                dismiss={dismiss}
                notificationId={notificationId}
                toggleSelection={toggleSelection}
                selectedProducts={selectedProducts}
              />
            </FullWidth>
          )
        }
      </Above>
    </Container>
  );
};

export default HelloRetailProductRecomCheckout;
