import { styled } from 'linaria/react';
import { css } from 'linaria';
import { toRem } from '../../../helpers';
import { ReactComponent as ChevronDown } from '../../../assets/svg/ChevronDown.svg';
import { ReactComponent as Check } from '../../../assets/svg/Check.svg';
import { theme } from '../../../components/Theme';

const VariantSelectorContainer = styled('div')`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
`;

const DropdownVariantSelectContainer = styled('div')``;

const ChevronIcon = styled(ChevronDown)`
  transform: rotate(0deg);
`;

const CheckIcon = styled(Check)`
  width: 14px;
  height: 14px;
`;

const dropdownStyles = css`
  // && wrapping needed to avoid non-deterministic styling
  && {
    flex: 1;
    word-break: normal;
    &.inline {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
    &.size-small {
      [data-flight-dropdown-button] {
        padding: 0.5em 1em;
      }
    }

    label {
      display: inline-block;
      font-size: 0.85em;
      white-space: nowrap;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    [data-flight-dropdown-button='false'] {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid ${theme.colorScheme.black};
      border-radius: 8px;
      white-space: nowrap;
      background: ${theme.colorScheme.white};
      padding: 0.795em 1em;
      font-size: 0.875em;
      font-weight: ${theme.fontWeights.bold};
      line-height: 1.125em;
      justify-content: space-between;
      gap: 0.5em;
    }

    [data-flight-dropdown-button='true'] {
      margin: 0 10px 5px 0;
      position: relative;
      display: block;
      min-width: 214px;
      color: #4d4f5c;
      background: #fff;
      border: 1px solid #efefef;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      box-sizing: border-box;
      svg {
        position: absolute !important;
        right: 1rem !important;
        top: 1rem !important;
      }
      &[aria-expanded='true'] {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
export const dropdownItemsStyles = css`
  && {
    width: 100%;
    padding: ${toRem(4)} 0;
    margin-top: ${toRem(2)};
    border-radius: ${toRem(12)};
    overflow: hidden;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    background: ${theme.colorScheme.white};
    [data-flight-dropdown-item] {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #ebebeb;
      padding: ${toRem(8)} ${toRem(16)};
      font-size: ${toRem(14)};
      font-weight: ${theme.fontWeights.bold};
      line-height: ${toRem(18)};

      label {
        width: 100%;
      }
      :first-child {
        border-top: 0;
      }
      :hover {
        background-color: #f5f5f5;
        color: ${theme.colorScheme.black};
      }
    }
    &.invalid [data-flight-dropdown-button] {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Label = styled('label')`
  font-size: ${toRem(12)};
  font-weight: ${theme.fontWeights.medium};
  padding-bottom: ${toRem(8)};
`;

const style = {
  VariantSelectorContainer,
  DropdownVariantSelectContainer,
  CheckIcon,
  ChevronIcon,
  dropdownStyles,
  dropdownItemsStyles,
  Label
};

export default style;
