import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../../components/Theme';
import cartQuery from '../../../gql/queries/cartQuery.gql';
import HelloRetailProductRecomCheckout from '../../../blocks/HelloRetail/HelloRetailProductRecomCheckout';
import MaxWidth from '../../../components/Layout/MaxWidth';
import ButtonWithLoading from '../../../components/ui/Button';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import Button from '../../../components/ui/Button';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import { useNotification } from '@jetshop/core/components/Notifications';
import addMultipleToCartMutation from '../../../gql/mutations/addMultipleToCart.gql';
import { ReactComponent as CartIcon } from '../../../assets/svg/Cart.svg';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Above } from '@jetshop/ui/Breakpoints';

const Container = styled('aside')`
  border: 2px solid #eaeaea;

  /* ${theme.above.sm} {
    width: 100%;
  }
  ${theme.below.sm} {
    width: 100%;
  } */
  background-color: ${theme.colorScheme.white};
  height: 100%;
`;

const ProductImageWrapper = styled('div')`
  width: 90px;
  margin-right: 1rem;

  ${theme.below.md} {
    height: 4rem;
    width: 4rem;
  }
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Product = styled('div')`
  display: flex;
  height: 90px;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  /* margin: ${theme.space[2]}; */
  padding: 6px 24px 6px 24px; 

  ${theme.below.sm} {
    /* padding: 5px 10px;  */
    /* margin-bottom: 11px; */
    height: 60px;
  }
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  color: #50d934;
  padding-right: 0.5rem;
`;

const Header = styled('h3')`
  /* font-size: ${theme.fontSizes[2]}; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

const ProductName = styled('p')`
  /* font-size: ${theme.fontSizes[0]}; */
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  ${theme.below.sm} {
    font-size: 12px;
  }
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const CheckoutContainer = styled('div')`
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  z-index: 100;

  ${theme.below.lg} {
    border-top: 1px solid #eaeaea;
    bottom: 0;
    padding: 0px 0px 0.5rem 0px !important;
  }

  ${theme.below.md} {
    border-top: 1px solid #eaeaea;
    bottom: 0;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /* Adjust .add-all position */
  .add-all {
    text-align: center;
    background-color: black;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    border: 0px;
    border-radius: 8px !important;
    padding: 12px 24px !important;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    grid-column: span 2;

    :hover {
      background-color: white;
      color: black;
      border: 0px;
      -webkit-box-shadow: inset 0px 0px 0px 1px #000;
      -moz-box-shadow: inset 0px 0px 0px 1px #000;
      box-shadow: inset 0px 0px 0px 1px #000;
    }

    order: 2;

    ${theme.below.md} {
      order: 1;
      max-width: 100%;
      margin: 0.5rem;
      font-size: 14px;
      padding: 8px !important;
    }
  }
`;

const CheckoutButton = styled(ButtonWithLoading)`
  margin: 0.5rem;
  position: relative;
  overflow: hidden;
  order: 3;
  background: linear-gradient(
    ${theme.colorScheme.addtocartswipeangle},
    ${theme.colorScheme.addtocartlight},
    ${theme.colorScheme.addtocartdark}
  );
  color: ${theme.colorScheme.addtocarttext};
  border: none;
  border-radius: 8px !important;
  padding: 12px 24px !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  ${theme.below.md} {
    height: 100%;
    font-size: 14px;
    padding: 0px !important;
    margin-left: 0.25rem;
  }
  svg path {
    fill: ${theme.colorScheme.addtocarttext};
  }
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: none !important;
    color: ${theme.colorScheme.addtocarttext} !important;
    svg path {
      fill: ${theme.colorScheme.addtocarttext} !important;
    }
  }

  /* Idle streak: Moves continuously left to right */
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 10%,
      rgba(${theme.colorScheme.addtocartswipe}, 0.5) 50%,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 90%
    );
    filter: blur(8px);
    transform: skewX(-20deg);
    animation: streak 13s linear infinite;
  }

  /* Hover streak: Bursts from center and expands outwards */
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 30%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  :hover::after {
    animation: ripple 1s forwards;
  }

  /* Keyframes for the hover ripple effect */
  @keyframes ripple {
    0% {
      width: 0%;
      height: 30%;
      opacity: 1;
    }
    100% {
      width: 300%;
      height: 300%;
      opacity: 0;
    }
  }

  /* Keyframes for the idle streak */
  @keyframes streak {
    0% {
      left: -100%;
    }
    23.08% {
      /* Streak moves for 3s out of 13s total */
      left: 150%;
    }
    100% {
      left: 150%;
    }
  }
`;
const ContinueButton = styled('button')`
  margin: 0.5rem;
  text-align: center;
  background-color: white;
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 0px;
  -webkit-box-shadow: inset 0px 0px 0px 1px #000;
  -moz-box-shadow: inset 0px 0px 0px 1px #000;
  box-shadow: inset 0px 0px 0px 1px #000;
  border-radius: 8px !important;
  padding: 12px 24px !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  order: 1;
  :hover {
    background-color: black;
    color: white;
  }
  ${theme.below.md} {
    order: 2;
    height: 100%;
    font-size: 14px;
    padding: 0px !important;
    margin-right: 0.25rem;
  }
`;

const RecomContainer = styled(MaxWidth)`
  padding: 0px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colorScheme.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

const ProductToast = ({
  product,
  cart,
  dismiss,
  selectedVariation,
  quantity,
  notificationId,
  error,
  loading
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const recomNamePurchased = { value: 'upsell_step' };
  const titlePurchased = { value: t('Others have also bought') };
  const productNumbers = [product.articleNumber];
  const [trigger] = useNotification();

  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const validItems = selectedProducts;
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    selectedProducts,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <CartIcon />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  // Toggle selection when a checkbox is clicked
  const toggleSelection = product => {
    const articleNumber = product.articleNumber;
    setSelectedProducts(prevSelected => {
      const alreadySelected = prevSelected.some(
        item => item.articleNumber === articleNumber
      );
      if (alreadySelected) {
        return prevSelected.filter(
          item => item.articleNumber !== articleNumber
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                // sizes={80}
                aspect={'1:1'}
                alt={image.alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <ProductName>{product.name}</ProductName>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('Added {quantity} items to bag', { quantity })}
              </Header>
            )}

            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
        <RecomContainer>
          <HelloRetailProductRecomCheckout
            recomName={recomNamePurchased}
            title={titlePurchased}
            productNumbers={productNumbers}
            notificationId={notificationId}
            dismiss={dismiss}
            toggleSelection={toggleSelection}
            selectedProducts={selectedProducts}
          />
        </RecomContainer>
        <CheckoutContainer>
          <ContinueButton onClick={() => dismiss(notificationId)}>
            {t('Continue shopping')}
          </ContinueButton>

          <Above breakpoint="md">
            {matches =>
              matches ? (
                <FlyoutTrigger id="cart-flyout">
                  {flyout => (
                    <Button
                      hideArrow
                      onClick={async () => {
                        await dismiss(notificationId);
                        // Delay calling addToCart so that the dismissal is complete
                        setTimeout(async () => {
                          await addToCart();
                          flyout.showTarget();
                        }, 300); // Adjust timeout based on the dismiss animation duration
                      }}
                      loading={mutationLoading}
                      className="add-all"
                    >
                      {mutationLoading
                        ? t('One moment…')
                        : t(
                            '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                            { productCount: validItemCount }
                          )}
                    </Button>
                  )}
                </FlyoutTrigger>
              ) : (
                <DrawerTrigger preventOverflow={true} id="cart-drawer">
                  {drawer => (
                    <Button
                      hideArrow
                      onClick={async () => {
                        await dismiss(notificationId);
                        // Delay calling addToCart so that the dismissal is complete
                        setTimeout(async () => {
                          await addToCart();
                          drawer.showTarget();
                        }, 300); // Adjust timeout based on the dismiss animation duration
                      }}
                      loading={mutationLoading}
                      className="add-all"
                    >
                      {mutationLoading
                        ? t('One moment…')
                        : t(
                            '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                            { productCount: validItemCount }
                          )}
                    </Button>
                  )}
                </DrawerTrigger>
              )
            }
          </Above>

          {cart && cart.externalCheckoutUrl && (
            <ChannelContext.Consumer>
              {({ selectedChannel }) => (
                <CheckoutButton
                  href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                  onClick={() => {
                    track(trackCartCheckoutEvent({ cart }));
                  }}
                >
                  {t('To checkout')}
                </CheckoutButton>
              )}
            </ChannelContext.Consumer>
          )}
        </CheckoutContainer>
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
